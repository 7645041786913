<template>
    <div class="container_details">
        <!-- 头部 -->
        <clone-tab style="margin-bottom: 30px" :bgcShow="bgcShow"></clone-tab>

        <!-- 审核记录 -->
        <audit-process :tableData="tableData" :type="type" :dataList="dataList"></audit-process>
    </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import auditProcess from "../../components/expenditure/auditProcess";
import {applyDetail_api,shareDetail_api} from "@/api/deal";
// 数据
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      bgcShow: true,
      dataList: {},
      type:1,
      tableData:{}
    };
  },
  components: {
    cloneTab,
    auditProcess
  },
  created() {
    this.getDetails(this.$route.query.id);
    this.type = this.$route.query.type;
    console.log(this.type)
    if(this.$route.query.type!=1){
      this.getShareDetails(this.$route.query.id);
    }else{
      this.getDetails(this.$route.query.id);
    }
    this.getSetting()
  },
  methods: {
    async getSetting() {
      let data = {
        config: ["union_member_fee","shareholder_renew_shipping_fee"]
      };
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        console.log(res.data, "111111");
        this.tableData = res.data;
      }
    },
    getDetails(id) {
      applyDetail_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
          // if(this.dataList.info_state==1){
          //   this.type=1
          // }else{
          //   this.type=""
          // }
          if (!this.dataList.pay_at) {
            this.dataList.levelStatus = 1;
          }
          if (this.dataList.pay_at && this.dataList.audit_state == 1) {
            this.dataList.levelStatus = 2;
          }
          if (
            this.dataList.audit_state == 2 &&
            this.dataList.shipments.shipments_info == "null"
          ) {
            this.dataList.levelStatus = 3;
          }
          if (this.dataList.shipments.shipments_info) {
            this.dataList.levelStatus = 4;
          }
        }
      });
    },
    getShareDetails(id) {
      shareDetail_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
          if(!this.dataList.pay_at){
              this.dataList.levelStatus =  1
          }
          if(this.dataList.pay_at&&this.dataList.audit_state==1){
              this.dataList.levelStatus =  2
          }
          if(this.dataList.audit_state==2){
              this.dataList.levelStatus =  3
          }

        }
      });
    },
  }
};
</script>
<style>
.container_details {
  padding-top: 40px;
}
</style>